var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.trust ? _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("trust.prop-info")))]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_fixed_saham_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("property-type2")) + " ")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("Simpanan")])])]), _vm._m(0)]), _vm._m(1), _c('hr'), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "onecent_username"
    }
  }, [_vm._v("Username")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.username,
      expression: "form.username"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "onecent_username"
    },
    domProps: {
      "value": _vm.form.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "username", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "onecent_wallet_name"
    }
  }, [_vm._v("Wallet Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.wallet_name,
      expression: "form.wallet_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "onecent_wallet_name"
    },
    domProps: {
      "value": _vm.form.wallet_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "wallet_name", $event.target.value);
      }
    }
  })])])])])])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" Nama Saham ")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("Akaun")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_fixed_saham_type"
    }
  }, [_vm._v("Nama Organisasi ")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v("One Cent")])])])]);
}]

export { render, staticRenderFns }